// store/store.js
import { configureStore } from "@reduxjs/toolkit";
import subscribeReducer from "./subscribeSlice"

export const store = configureStore({
  reducer: {
    subscribe: subscribeReducer, // Add reducer here
  },
});

export default store;
