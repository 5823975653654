import React, { useState } from 'react'
import useBgLoaded from '../custom-hooks/useBgLoaded'
import { useDispatch } from 'react-redux';
import { subscribeEmail } from '../store/subscribeSlice';
import {toast} from "sonner";

const Subscribe = () => {
  const dispatch = useDispatch();
  const { ref, inView, bgLoaded, setBgLoaded } = useBgLoaded();
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

	if (inView && !bgLoaded) {
		setBgLoaded(true);
	}
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Start loading

    if (!email) {
      toast.error("Please enter an email address.");
      setIsSubmitting(false);
      return;
    }
try{
    // Call your async action 
    const response = await dispatch(subscribeEmail({ email })).unwrap();
      toast.success(response.message || "Email Subscribed successfully");
      setEmail("");
   
    } catch (error) {
      console.error("Error subscribing", error);
      toast.error(error || "Subscribing failed, please try again later.")
    } finally {
      setIsSubmitting(false); // Stop loading
    }
  };
  return (
    <div ref={ref} className={`${
						bgLoaded ? 'bg-subscribe-image' : ''
					} h-[400px] md:h-[752px] bg-no-repeat bg-full md:bg-cover relative font-dmsans`}>
    {/* Sign up Today */}
<div className='absolute top-[24px] left-[24px] md:top-[69px] md:left-[48px] flex flex-col items-start gap-1 md:gap-[12px] w-[223px] h-[165px] md:w-[448px] md:h-[293px] rounded-[20px] border-[2px] bg-subcribeBackground border-[#F5F5F54D] backdrop-blur-[10px] py-4 px-4 md:px-[32px] md:py-[53px]'>
<h4 className='font-[700] text-[14px] leading-[18px] md:text-[24px] md:leading-[31px] text-white'>Run Your Entire Auto Repair Shop with One Tool!</h4>
<p className='text-[12px] leading-[16px] md:text-[16px] font-[400] md:leading-[21px] text-[#E2E0E0]'>Boost your business efficiency with Hamzaa</p>
<a href="https://dashboard.hamzaa.ca/signup" className='mt-1 md:mt-[20px] w-[130px] h-[52px] md:w-[157px] md:h-[60px] rounded-[8px] border-[2px] border-[#F0FDFA] py-4 px-3 md:py-[18px] md:px-[20px] bg-primary text-[14px] leading-[20px] md:text-[16px] font-[700] md:leading-[24px] flex justify-center items-center text-center text-[#F0FDFA]'>Sign Up Today</a>
</div>
{/* Subscribe to Our News Letter */}
<div className="absolute bottom-0 right-0 left-0 w-full bg-subcribeBackground py-4 md:py-[40px] md:px-[30px] flex flex-col items-center gap-[9px] md:gap-[19px] h-[89px] md:h-[185px]">
  <h4 className='font-[700] text-[12px] leading-[16px] md:text-[24px] md:leading-[31px] text-[#F0FDFA]'>Subscribe to our Newsletter</h4>
  <form onSubmit={handleSubmit} className='flex px-[30px] md:px-0'>
    <input value={email}  onChange={(e) => setEmail(e.target.value)}  type='email' placeholder='Enter your email address' className='w-full md:w-[511px] md:h-[56px] h-[32px] rounded-[4px] rounded-tr-none rounded-br-none py-[17px] px-[16px] bg-[#F9FBFE] text-[10px] leading-[13px] md:text-[16px] font-[400] md:leading-[21px] text-[#161414] outline-primary'/>
    <button disabled={isSubmitting} type='submit' className={`w-[67px] md:w-[154px] h-[32px] md:h-[56px] rounded-tr-[4px] rounded-br-[4px] flex justify-center items-center py-[17px] px-[30px] bg-primary font-montserrat font-[500] text-[10px] leading-[12px] md:text-[16px] md:leading-[20px] text-center text-[#F9FBFE] ${isSubmitting ? "cursor-not-allowed opacity-50" : "cursor-pointer"}`}>{isSubmitting ? "Subscribing..." : "Subscribe"}</button>
  </form>
</div>
    </div>
  )
}

export default Subscribe