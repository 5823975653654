import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const baseUrl = process.env.REACT_APP_API_URL;

const initialState = {
  email: [],
};

export const subscribeEmail = createAsyncThunk(
  "auth/subscribeEmail",
  async ({ email }, thunkAPI) => {
    try {
      //   const state = thunkAPI.getState();
      //   const token = state.auth.token.data.token;
      const response = await fetch(`${baseUrl}/v1/newsletter/subscribe`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          //   Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          email,
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to subscribe email");
      }

      return data; // Assuming API returns success message
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const subscribeSlice = createSlice({
  name: "email",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(subscribeEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(subscribeEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload.message;
      })
      .addCase(subscribeEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default subscribeSlice.reducer;
