import React, { useState } from "react";
import {  Link, useLocation } from "react-router-dom";
import useScrollTop from '../custom-hooks/useScrollToTop';
import { IoCloseOutline } from "react-icons/io5";
import Menu from '../assets/images/menu.svg'
import NavbarModal from "./NavbarModal";
// import RequestDemo from "./RequestDemo";
import { PopupModal } from "react-calendly";


const Navbar = () => {
  const location = useLocation();
  const { scrollToTop } = useScrollTop();
  const [navbarModalOpen, setNavbarModalOpen] = useState(false);
  // const [demoModalOpen, setDemoModalOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
const openNavbarModal = () => {
  setNavbarModalOpen(true);
}
const closeNavbarModal = () => {
  setNavbarModalOpen(false);
}
  const isActive = (path) => location.pathname === path;
  const navLinks = [
    { id: 1, name: "About Us", path: "/aboutus" },
    { id: 2, name: "What We Offer", path: "/features" },
    { id: 3, name: "Pricing", path: "/pricing" },
    { id: 4, name: "Contact Us", path: "/contactus" },
  ];
  const handleHomeClick = ( ) => {
    scrollToTop();
    setNavbarModalOpen(false)
  }
  // const handleDemoModal = () => {
  //   setDemoModalOpen(true);
  // }
  return (
    <>
    <div className="w-full bg-white h-[96px] border border-b-[2px] fixed top-[0px] left-[0px] flex justify-between items-center px-6 py-4 lg:px-[80px] lg:py-[34px] font-dmsans z-40">
      <div className="flex items-center lg:gap-[60px]">
        <Link  to="/" onClick={handleHomeClick}>
        <h2 className="font-miltonian font-[400] text-[24px] leading-[29px] text-foot">Hamzaa</h2>
        </Link>
        <div className="hidden lg:flex gap-[32px]">
          {navLinks.map((link, index) => (
            <Link to={link.path} onClick={scrollToTop}>
              <button
                key={index}
                className={`font-[400] text-[18px] leading-[28px] ${
                  isActive(link.path)
                    ? "underline underline-offset-4 decoration-[2px] decoration-[#0E4E72] text-[#0E4E72]"
                    : "text-[#161414]"
                }`}
              >
                {link.name}
              </button>
            </Link>
          ))}
        </div>
      </div>
      <div className="hidden lg:flex items-center gap-[60px]">
        <div className="flex items-center gap-[32px]">
          <a href='https://dashboard.hamzaa.ca/signin'  rel="noreferrer" className="font-[400] text-[18px] leading-[28px] text-[#161414]">
            Login
          </a>
          <a href="https://dashboard.hamzaa.ca/signup"  rel="noreferrer" className="font-[400] text-[18px] leading-[28px] text-[#161414]">
            Sign up
          </a>
        </div>
        <button  onClick={() => setIsOpen(true)}  className="w-auto h-[60px] rounded-[8px] border-[#0A0A0A] border-[2px] py-[18px] px-4 bg-[#2E844A] font-[700] text-[16px] leading-[24px] text-center flex justify-center items-center text-white ">
        Request a Demo
        </button>
        <PopupModal
        url="https://calendly.com/hamzaa-support"
        onModalClose={() => setIsOpen(false)}
        open={isOpen}
        rootElement={document.getElementById("root")}
      />
      </div>
      <div  className="lg:hidden flex justify-center items-center w-[54px] h-[54px] rounded-[12px] p-[15px] bg-[#F9F9F9] cursor-pointer">
      {navbarModalOpen ?  <IoCloseOutline className="w-6 h-6" onClick={closeNavbarModal}/> :
      <img src={Menu} alt="Menu icon" className="w-6 h-6" onClick={openNavbarModal}/>
     
    }
      </div>
     
    </div>
     {navbarModalOpen && <NavbarModal setNavbarModalOpen={setNavbarModalOpen} setIsOpen={setIsOpen} />}
     {/* {demoModalOpen && <RequestDemo closeModal={() => setDemoModalOpen(false)}/>} */}
     </>
  );
};

export default Navbar;
