import React from "react";
import { useNavigate } from "react-router-dom";

const Error = () => {
  const navigate = useNavigate();
  const handleBackButton = () => {
navigate('/');
  }
  return (
    <div className="flex flex-col gap-6 justify-center items-center h-[100vh] pt-[50px] bg-[#F8FAFF]">
      <h2 className="text-center text-[30px] text-primary">
        Error 404 - Page not found
      </h2>
        <button onClick={handleBackButton} className="border border-primary p-4 rounded-md bg-primary text-white">
        Redirect to Home Page
        </button>
    </div>
  );
};

export default Error;
