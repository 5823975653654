import React, {useState} from 'react'
import InputField from "../components/InputField"
import { HiArrowUpTray } from "react-icons/hi2";


const Careers = () => {
  const [typeCategory, setTypeCategory] = useState("");
  const category = [{label: "Engineering", value: "Engineering" }, {label: "Product", value: "Product"}, {label: "Marketing", value:"Marketing"}];
  const [fileName, setFileName] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name); // Set the file name when a file is selected
    }
  };

   return (
      <div className='mt-[160px] lg:mt-[176px] mb-[60px] lg:mb-[97px] font-dmsans max-w-full overflow-hidden'>
        <div className='flex flex-col gap-[6px]  items-center justify-center mb-[36px] lg:mb-[60px] px-6  xl:px-[247px]'>
  <h1 className='font-spaceGrotesk font-[700] md:font-[500] text-[24px] md:text-[32px] leading-[40px] tracking-[0.3px] text-black text-center'>Career</h1>
  <p className='font-[400] text-[14px] leading-[20px] md:text-[16px] md:leading-[24px] tracking-[0.3px] text-[#48494D] text-center'>Join the Hamzaa Talent Network and stay updated on exciting career opportunities, projects, and events.
  Let’s build something extraordinary together!</p>
        </div>
          <div className='w-full flex justify-center items-center px-6  lg:px-[80px]'>
            <form className='w-full flex flex-col gap-[24px]'>
            <div className='flex lg:flex-row flex-col items-center gap-[24px] w-full'>
            <InputField
                                  label='First Name'
                                  // value={}
                                  // handleValue={}
                                  name='firstName'
                                  type='text'
                                  className='w-full'
                                 inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px] border-[#D9D9D9]"
                  asteriks={true}
                              />
            <InputField
                                  label='Last Name'
                                  // value={}
                                  // handleValue={}
                                  name='lastName'
                                  type='text'
                                  className='w-full'
                                  inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px] border-[#D9D9D9]"
                  asteriks={true}
                              />
                </div>
            <div className='flex lg:flex-row flex-col items-center gap-[24px] w-full'>
            <InputField
                                  label='Email address'
                                  // value={}
                                  // handleValue={}
                                  name='email'
                                  type='email'
                                  className='w-full'
                                  inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px] border-[#D9D9D9]"
                  asteriks={true}
                              />
            <InputField
                                  label='Phone Number'
                                  // value={}
                                  // handleValue={}
                                  name='phoneNumber'
                                  type='text'
                                  className='w-full'
                                  inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px] border-[#D9D9D9]"
                  asteriks={true}
                              />
                </div>
            <div className='flex lg:flex-row flex-col items-center gap-[24px] w-full'>
            <InputField
                                  label='Location'
                                  // value={}
                                  // handleValue={}
                                  name='location'
                                  type='text'
                                  className='w-full'
                                  inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px] border-[#D9D9D9]"
                  // asteriks={true}
                              />
            <InputField
                                  label='LinkedIn URL'
                                  // value={}
                                  // handleValue={}
                                  name='linkedIn'
                                  type='text'
                                  className='w-full'
                                  inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px] border-[#D9D9D9]"
                  // asteriks={true}
                              />
                </div>
            <div className='flex flex-col lg:flex-row items-center gap-[24px] w-full'>
           
            <InputField
                                  label='Personal Website/Portfolio'
                                  // value={}
                                  // handleValue={}
                                  name='portfolio'
                                  type='text'
                                  className='w-full'
                                  inputClassName="w-full h-[58px] md:h-[69px] rounded-[12px] md:rounded-[13px] border-[#D9D9D9]"
                  // asteriks={true}
                              />
                <InputField
								label='What team are you interested in? * '
								value={typeCategory}
								handleValue={setTypeCategory}
								name='team'
								className='w-full'
                asteriks={true}
                type="select"
                options={category}
                 selectClassName="w-full flex justify-center items-center rounded-[12px] md:rounded-[13px] h-[58px] md:h-[69px]"
							/>
                </div>
              
                <div className="flex flex-col justify-center w-full">
      {/* Label as the styled upload button */}
      <label htmlFor='resume'  className="text-[14px] leading-[16px] md:leading-[18px] text-[#020202] mb-[4px] block">Resume<span className="text-[#FF0000]">*</span></label>
      <label
        htmlFor="resume-upload"
        className="flex items-center gap-1 justify-start w-full rounded-[12px] md:rounded-[13px] h-[58px] md:h-[69px] px-4 md:px-8 py-2 text-sm font-[400] text-[20px] leading-[30px] text-[#161414] border border-[#D9D9D9] cursor-pointer focus:border-primary outline-primary "
      >
<HiArrowUpTray className='text-[#141B34] w-4 h-4 md:w-6 md:h-6' />
       <span>{fileName ? "Change File" : "Upload Resume"}</span> 
      </label>
     
        <p className="mt-1 text-[14px] leading-[16px] md:leading-[18px] text-[#161414]">{ fileName ? `Selected File: ${fileName}` : "Select a file (.pdf, doc, .docx)." }</p>
    
      {/* Hidden file input */}
      <input
        id="resume-upload"
        type="file"
        accept=".pdf,.doc,.docx"
        onChange={handleFileChange}
        className="hidden"
      />

      {/* Display the selected file name */}
    
    </div>
<div className='flex flex-col gap-2 items-center text-center my-10'>
  <h4 className='italic font-[400] text-[16px] md:text-[20px] leading-[30px] text-[#4F5459]'>By submitting you agree to Hamzaa’s <a href='/terms' className='text-[#0D61FF]'> terms {" "}</a>and{" "}<a href='/privacy' className='text-[#0D61FF]'>privacy policy</a>.</h4>
  <p className='font-[400] text-[16px] md:text-[20px] leading-[30px] text-[#161414]'>Save your info to register faster next time & hear about other opportunities.</p>
</div>
                <div className='flex justify-center items-center '>
                <button className='w-auto h-[52px] lg:w-full lg:h-[60px] flex justify-center items-center rounded-[8px] py-[18px] px-[20px] bg-primary border-[2px] border-none font-[700] text-[14px] leading-[20px] md:text-[16px] md:leading-[24px] text-center text-white'>Submit and Save</button>
                </div>
            </form>
          </div>
     
      </div>
   
  )
}

export default Careers